/* page content */
.page {
	padding-bottom: 100px;
	.news1, .news {
		.date {
			color: #063763;
		}
	}
	.page_nav {
		.active {
			color: #3361eb;
		}
	}
	a {
		&:active, &:focus {
			text-decoration: none;
		}
	}
	@media screen and (max-width: 1170px) {
		#photos-list td img {
			width: 100%;
			height: auto;
		}
	}
	@media screen and (max-width: 991px) {
		padding-bottom: 50px;
	}
	@media screen and (max-width: 480px) {
		p img, p strong img {
			width: 100%!important;
			height: auto!important;
			display: block!important;
			float: none!important;
			margin: 0 0 10px 0!important;		
		}
		#photos-list td {
			width: 50%;
			@include inline-block;
		}
	}
}
/* breadcrumbs */
.breadcrumbs {
	font-size: 16px;
	position: relative;
	text-align: center;
	margin-bottom: 90px;
	&:after {
		content: '';
		background-color: rgba(0,0,0,0.3);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	.container {
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		margin: -50px auto 0;
		z-index: 1;
		h1 {
			color: #fff;
			margin: 0 0 20px 0;
			&:after {
				background-color: #fff;
			}
		}
		ul {
			@include ul-default;
			li {
				padding: 5px 0px;
				color: #1676b5;
				font-size: 12px;
				@include inline-block;
				a {
					font-size: 12px;
					color: #fff;
					text-decoration: none;
					&:hover {
						color: #1676b5;
					}
				}
				&:after {
					content: '\f105';
					font-family: 'fontAwesome';
					font-size: 14px;
					margin: 1px 4px 0px 9px;
					color: #fff;
					@include inline-block;
				}
				&:last-of-type:after {
					display: none;
				}
			}
		}
	}
	> img {
		display: block;
		width: 100%;
	}
	@media screen and (max-width: 1700px) {
		min-height: 180px;
		.container {
			position: relative;
			padding: 35px 15px;
			margin: 0 auto;
		}
		> img {
			width: auto;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
	@media screen and (max-width: 1199px) {
		margin-bottom: 25px;
	}
	@media screen and (max-width: 767px) {
		min-height: 0px;
	}
}
/* breadcrumbs */

/* page news */
.news__block {
	display: table;
	width: 100%; 
	margin-bottom: 50px;
	.news__block__left, 
	.news__block__right {
		display: table-cell;
		vertical-align: top;
		overflow: hidden;
	}
	.news__block__left {
		width: 360px;
		img {
			display: block;
			transition: 400ms;
		}
	}
	.news__block__right {
		width: calc(100% - 360px);
		background-color: #f8f8f8;
		padding: 30px 40px;
		position: relative;
		h5 {
			min-height: 38px;
			margin: 0 0 20px 0;
		}
		p {
			min-height: 66px;
		}
		.date {
			color: #063763;
			@include inline-block;
		}
		.more {
			font-family: $mediumItalic;
			font-size: 14px;
			text-transform: uppercase;
			float: right;
			position: relative;
			padding-right: 27px;
			@include inline-block;
			&:after {
				content: '\f105';
				font-family: 'fontAwesome';
				font-size: 22px;
				color: #063763;
				position: absolute;
				top: -2px;
				right: 0;
				transition: 300ms;
			}
			&:hover:after {
				right: -5px;
			}
		}
	}
	&:hover {
		text-decoration: none;
		.news__block__left {
			img {
				transform: scale(1.1);
			}
		}
	}
	@media screen and (max-width: 767px) {
		margin-bottom: 25px;
		.news__block__left {
			display: block;
			width: 100%;
			img {
				display: block;
				width: 100%;
			}
		}
		.news__block__right {
			display: block;
			width: 100%;
			padding: 15px;
		}
	}
}
/* page news */

/* page partners */
.partners__table {
	width: 100%;
	vertical-align: middle;
	text-align: center;
	tr td {
		height: 250px;
		position: relative;
		border: 1px solid #454545;
		&:before {
			content: '';
			background-color: rgba(7, 47, 87, 0.8);
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			transition: 300ms;
			opacity: 0;
		}
		img {
			display: block;
			max-width: 100%;
			margin: 0 auto;
		}
		span {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			color: #fff;
			padding: 10px;
			top: 50%;
			box-sizing: border-box;
			opacity: 0;
			transform: translateY(-50%);
			transition: 300ms;
		}
		&:hover {
			&:before {
				opacity: 1;
			}
			span {
				opacity: 1;
			}
		}
	}
	@media screen and (max-width: 991px) {
		tr td span {
			opacity: 1;
			padding-top: 40px;
		}
	}
	@media screen and (max-width: 767px) {
		tr td {
			display: block;
			width: 100%;
			span {
				padding-top: 15%;
			}
			img {
				margin-top: 50px;
			}
		}
	}
}
/* page partners */

/* page services */
.page {
	.our--works {
		.our--works__col .our--works__col__info {
			background-color: #fff;
			padding: 40px 20px 15px 100px;
			&:after {
				display: none!important;
			}
		}
		margin-bottom: 50px;
	}
	@media screen and (max-width: 1600px) {
		.our--works .our--works__col .our--works__col__img img {
			width: 100%;
			height: auto;
		}
	}
	@media screen and (max-width: 1440px) {
		.our--works .our--works__col .our--works__col__info {
			padding: 30px 20px 15px 75px;
		}
	}
	@media screen and (max-width: 991px) {
		.our--works {
			margin-bottom: 25px;
			.our--works__col {
				margin-bottom: 25px;
				.our--works__col__info {
					background-color: rgba(0,0,0,0.5);
					padding: 50px 20px 20px 20px;
					.btn {
						color: #fff;
						border: 1px solid #fff;
						&:after {
							color: #fff;
							border-left: 1px solid #fff;
						}
					}
				}
				&.col--right .our--works__col__img h5 {
					right: auto;
					left: 0;
				}
				.our--works__col__img {
					display: block;
					width: 100%;
				}
			}
		}
	}
	@media screen and (max-width: 767px) {
		.our--works .our--works__col .our--works__col__img {
			img {
				min-height: 400px;
				width: auto;
			}
		}
	}
}
.our--works__slider {
	margin-bottom: 50px;
	.slick-slide {
		outline: none;
		img {
			display: block;
			width: 100%;
		}
	}
	.slick-arrow {
		width: 40px;
		height: 40px;
		border: 1px solid #fff;
		top: auto;
		bottom: 0;
		margin: 0;
		transition: 400ms;
		transform: translate(0);
		overflow: hidden;
		z-index: 1;
		&:before {
			display: none;
		}
		&:after {
			font-family: 'fontAwesome';
			font-size: 28px;
			color: #fff;
			line-height: 40px;
			margin-top: -2px;
		}
		&.slick-next {
			right: 0;
			&:after {
				content: '\f105';
				margin-right: -2px;
			}
		}
		&.slick-prev {
			left: auto;
			right: 39px;
			&:after {
				content: '\f104';
				margin-left: -2px;
			}
		}
		&:hover {
			background-color: #072f57;
		}
	}
	@media screen and (max-width: 991px) {
		margin-bottom: 25px;
	}
	@media screen and (max-width: 420px) {
		.slick-slide {
			img {
				height: 155px;
				width: auto;
			}
		}
	}
}
.works--unit {
	ul {
		@include ul-default;
	}
	.works--unit__list {
		margin-bottom: 10px;
		li {
			padding: 3px 5px 3px 0px;
		}
	}
}
/* page services */

/* page contacts */
.contacts {
	padding-bottom: 0;
	.contacts__left {
		h3 {
			margin: 0 0 50px 0;
		}
		ul {
			@include ul-default;
			li {
				margin-bottom: 25px;
			}
		}
	}
	.contacts__right {
		position: relative;
		padding-left: 120px;
		&:after {
			content: '';
			width: 1px;
			background-color: #c9c9c9;
			position: absolute;
			top: 0;
			left: 60px;
			bottom: 0;
		}
		h3 {
			margin: 0 0 50px 0;
		}
		.form {
			display: block;
			input[type="text"],
			input[type="email"],
			textarea {
				width: 100%;
				min-height: 40px;
				padding: 10px 30px 10px 15px;
				border: 1px solid #a0a0a0;
			}
			textarea {
				margin-bottom: 25px;
			}
			.col-sm-6 {
				margin-bottom: 25px;
			}
			.g-recaptcha {
				float: left;
				@include inline-block;
			}
			.btn {
				@include inline-block;
				float: right;
				margin-top: 15px;
			}
			.user,
			.phone,
			.email,
			.location,
			.write {
				position: relative;
				&:after {
					font-family: 'fontAwesome';
					font-size: 20px;
					color: #414141;
					position: absolute;
					top: 11px;
    				right: 25px;

				}
			}
			.user:after {
				content: '\f007';
			}
			.phone:after {
				content: '\f095';
			}
			.email:after {
				content: '\f0e0';
				font-size: 16px;
			}
			.location:after {
				content: '\f041';
			}
			.write:after {
				content: '\f040';
				font-size: 16px;
			}
		}
	}
	.map {
		margin-top: 100px;
	}
	@media screen and (max-width: 1199px) {
		.contacts__left, .contacts__right {
			h3 {
				margin-bottom: 25px;
			}
		}
	}
	@media screen and (max-width: 991px) {
		.contacts__right {
			padding-left: 15px;
			&:after {
				display: none;
			}	
		}
		.map {
			margin-top: 50px;
		}
	}
	@media screen and (max-width: 767px) {
		.map {
			> ymaps {
				height: 300px!important;
			}
		}
	}
	@media screen and (max-width: 580px) {
		.contacts__right {
			.form .g-recaptcha {
				transform: scale(0.9);
				transform-origin: 100% 0;
				float: right;
			}
		}
	}
}
/* page contacts */

/* page pagination */
.pagination {
	font-size: 0;
	ul {
		@include ul-default;
		li {
			margin: 0px 10px 10px 0px;
			@include inline-block;
			a {
				text-decoration: none;
				width: 25px;
				height: 35px;
				display: block;
				border: 1px solid #072f57;
				font-family: $medium;
				font-size: 14px;
				text-align: center;
				box-sizing: border-box;
				padding: 9px 2px 0px;
				outline: none;
				color: #414141;
				line-height: 1;
				&:hover {
					border: 1px solid #072f57;
					background-color: #072f57;
					color: #fff;
				}
			}
			&.active {
				a {
					border: 1px solid #072f57;
					background-color: #072f57;
					color: #fff;
				}
			}
			&.prev, &.next {
				border: none;
				a {
					border: 1px solid transparent;
					position: relative;
					&:hover {
						border: 1px solid transparent;
						background-color: transparent;
						color: #072f57;
					}
					&:after, &:before {
						content: '';
						display: block;
						font-family: 'fontAwesome';
						font-size: 25px;
						position: absolute;
						left: 0;
						right: 0;
						top: 50%;
						margin: -12px auto;
					}
					&:before {
						display: block;
					}
				}
			}	
			&.prev {
				a {
					&:after {
						content: '\f104';
					} 
					&:hover:after {
			
					}
				}
				&.full {
					a {
						&:after {
							right: auto;
							left: 4px;
						}
						&:before {
							content: '\f104';
							display: block;
							right: auto;
							left: 9px;
						}
					}
				}
			}
			&.next {
				a {
					&:after {
						content: '\f105';
					} 
					&:hover:after {
						
					}
				}
				&.full {
					a {
						&:after {
							left: auto;
							right: 4px;
						}
						&:before {
							content: '\f105';
							display: block;
							left: auto;
							right: 9px;
						}
					}
				}
			}
		}
	}
}
/* page pagination */

/* modal */
.modal {
	.modal-content {
		max-width: 420px;
		border-radius: 0px;
		margin: 0 auto;
		h4 {
			text-align: center;
			margin: 0;
		}
		.close {
			width: 25px;
			height: 25px;
			font-family: 'FontAwesome';
			font-size: 25px;
			position: absolute;
			right: 10px;
			top: 14px;
			opacity: 1;
			outline: none;
			&:after {
				content: '\f00d';
			}
			&:hover {
				opacity: 0.7;
			}
		}
		.btn {
			background-color: #ff0000;
			width: 100%!important;
		}
	}
	@media screen and (max-width: 768px) {
		.modal-content h4 {
			font-size: 18px;
		}
	}
}
/* modal */

/* page content */