/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}

/* main slider */
.slider--block {
	position: relative;
	.slider {
		margin-bottom: 0px!important;
		.slick-slide {
			overflow: hidden;
			position: relative;
			outline: none;
			&:after {
				content: '';
				background-color: rgba(0,0,0,0.1);
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
			}
			img {
				display: block;
				width: 100%;
				height: auto;
			}
			.slider__block {
				position: absolute;
				left: 0;
				right: 0;
				top: 50%;
				text-align: center;
				color: #fff;
				margin: 0 auto;
				transform: translateY(-65%);
				z-index: 1;
				.slider__block__title {
					display: table;
					font-size: 40px;
					text-transform: uppercase;
					color: #fff;
					border: 1px solid #ffffff;
					padding: 25px 50px;
					margin: 0 auto;
					line-height: 1.5;
				}
			}
		}
		.slick-dots {
			bottom: 30px;
			li {
				margin: 0px 2px;
				button {
					width: 15px;
					height: 15px;
					background-color: transparent;
					border-radius: 100%;
					border: 1px solid #ffffff;
					transition: 300ms;
					&:before {
						display: none;
					}
					&:hover {
						background-color: #ffffff;
					}
				}
				&.slick-active {
					button {
						background-color: #ffffff;
					}
				}
			}
		}
	}
	.btn--bottom {
		display: block;
		width: 45px;
		height: 45px;
		background-image: url(../img/arrow--down.png);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 25px;
		position: absolute;
		bottom: 100px;
		left: 0;
		right: 0;
		margin: 0 auto;
		border: 1px solid #fff;
		&:hover {
			background-position: center 18px;
		}
	}
	@media screen and (max-width: 1199px) {
		.slider .slick-slide .slider__block .slider__block__title {
			font-size: 30px;
			padding: 15px 25px;
			line-height: 1.2;
		}
	}
	@media screen and (max-width: 991px) {
		.slider {
			.slick-slide .slider__block .slider__block__title {
				font-size: 24px;
				padding: 15px;
			}
			.slick-dots {
				bottom: 10px;
			}
		}
		.btn--bottom {
			bottom: 60px;
		}
	}
	@media screen and (max-width: 580px) {
		.btn--bottom {
			display: none;
		}
	}
}
/* main slider */

/* main advantages */
.advantages {
	> img {
		display: block;
		width: 100%;
	}
}
/* main advantages */

/* main services */
.services {
	.services__nav {
		font-size: 0;
		@include ul-default;
		margin-bottom: 50px;
		li {
			@include inline-block;
			a {
				display: block;
				height: 45px;
				background-color: #f8f8f8;
				font-size: 16px;
				text-transform: uppercase;
				padding: 12px 35px;
				&:hover {
					text-decoration: none;
					background-color: #063763;
					color: #ffffff;
				}
			}
			&.active a {
				background-color: #063763;
				color: #ffffff;
			}
		}
	}
	.col-lg-7 {
		padding-left: 55px;
	}
	.services__title {
		display: block;
		font-family: $mediumItalic;
		text-transform: uppercase;
		margin-bottom: 35px;
	}
	img {
		display: block;
		width: 100%;
	}
	.btn {
		margin-top: 60px;
	}
	@media screen and (max-width: 1199px) {
		.col-lg-7 {
			padding-left: 15px;
		}
		.btn {
			margin-top: 40px;
		}
	}
	@media screen and (max-width: 767px) {
		.services__nav {
			margin-bottom: 25px;
			li {
				width: 50%;
				a {
					text-align: center;
				}
			}
		}
		.services__title {
			margin: 15px 0;
		}
		.btn {
			margin: 30px 0px 15px 0;
		}
	}
	@media screen and (max-width: 580px) {
		.services__nav li a {
			padding: 12px 10px;
			font-size: 14px;
		}
	}
}
/* main services */

/* main our--works */
.our--works {
	font-size: 0;
	.left {
		width: 66.66%;
		@include inline-block;
	}
	.right {
		width: 33.33%;
		@include inline-block;
	}
	.our--works__col {
		display: block;
		width: 100%;
		position: relative;
		overflow: hidden;
		@include inline-block;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			background-color: rgba(0,0,0,0.1);
		}
		.our--works__col__info {
			width: 50%;
			background-color: #f8f8f8;
			position: absolute;
			top: 0;
			bottom: 0;
			font-size: 16px;
			text-align: left;
			padding: 60px 20px 15px 100px;
			box-sizing: border-box;
			z-index: 1;
			@include inline-block;
			&:after {
				content: '';
				width: 0;
				height: 0;
				position: absolute;
				top: 50%;
				margin-top: -17px;
				border: 17px solid transparent;
				transition: 300ms;
				z-index: 1;
			}
			h5 {
				margin: 0 0 22px 0;
			}
			ul {
				@include ul-default;
				li {
					margin: 5px 0;
				}
			}
			.square--list {
				margin-top: 5px;
			}
			.btn {
				margin-top: 25px;
			}
		}
		.our--works__col__img {
			width: 50%;
			position: relative;
			@include inline-block;
			img {
				width: 100%;
				transition: 400ms;
			}
			h5 {
				min-height: 40px;
				text-transform: uppercase;
				background-color: rgba(7, 47, 87, 0.7);
				color: #fff;
				position: absolute;
				top: 0;
				left: 0;
				padding: 10px 18px;
				margin: 0;
				z-index: 1;
			}
		}
		&.col--left {
			text-align: right;
			.our--works__col__info {
				left: 0;
				&:after {
					border-left: 17px solid #f8f8f8;
					right: -34px;
				}
			}
		}
		&.col--right {
			text-align: left;
			.our--works__col__info {
				right: 0;
				&:after {
					border-right: 17px solid #f8f8f8;
					left: -34px;
				}
			}
			.our--works__col__img {
				h5 {
					left: auto;
					right: 0;
				}
			}
		}
		&.col--width {
			.our--works__col__img {
				width: 100%;
			}
		}
		&.col--height {
			padding-top: 63.3%;
			.our--works__col__info {
				display: block;
				width: 100%;
				height: 33.4%;
				top: 0;
				bottom: auto;
				&:after {
					left: 0;
					right: 0;
					top: auto;
					bottom: -34px;
					margin: 0 auto;
					border-top: 17px solid #f8f8f8;
				}
			}
			.our--works__col__img {
				display: block;
				width: 100%;
			}
		}
		&:hover {
			color: #414141;
			img {
				transform: scale(1.1);
			}
			.our--works__col__info{
				h5 {
					color: #063763;
				}
			}
		}
	}
	@media screen and (max-width: 1700px) {
		.our--works__col {
			.our--works__col__info {
				padding: 40px 20px 15px 75px;
			}
		}
	}
	@media screen and (max-width: 1600px) {
		.our--works__col {
			.our--works__col__info {
				padding: 20px 20px 15px 30px;
			}
			.our--works__col__img {
				img {
					height: 300px;
					width: auto;
				}
			}
		}
		.right {
			.our--works__col.col--height {
				padding-top: 300px;
				.our--works__col__info {
					height: 300px;
				}
				.our--works__col__img img {
					height: 600px;
				}
			}
		}
	}
	@media screen and (max-width: 1440px) {
		.our--works__col .our--works__col__info {
			h5 {
				margin-bottom: 10px;
			}
			ul li {
				margin: 2px 0;
			}
		}
	}
	@media screen and (max-width: 991px) {
		.left, .right {
			display: block;
			width: 100%;
			.our--works__col {
				padding-top: 0px;
				&.col--height {
					padding-top: 0;
					.our--works__col__info {
						height: 100%;
					}
					.our--works__col__img {
						img {
							height: auto;
							width: 100%;
						}
					}
				}
				&.col--width  {
					.our--works__col__img {
						img {
							min-height: 0px;
							width: 100%;
						}
					}
				}
				.our--works__col__img {
					width: 100%;
					img {
						height: auto;
						min-height: 300px;
						width: 100%;
					}
				}
			}
		}
		.our--works__col {
			.our--works__col__info {
				width: 100%;
				background-color: rgba(0,0,0,0.5);
				color: #fff;
				padding: 30px;
				&:after {
					display: none;
				}
				h5 {
					color: #fff;
				}
				.square--list li:before {
					background-color: #fff;
				}
			}
		}
	}
}
/* main our--works */

/* main partners */
.partners {
	.partners__slider {
		padding: 0 50px;
		.slick-slide {
			outline: none;
			margin: 0 20px;
			img {
				max-width: 100%;
				margin: 0 auto;
				transition: 300ms;
			}
			&:hover {
				img {
					filter: grayscale(100%);
				}
			}
		}
		.slick-arrow {
			width: 30px;
			height: 32px;
			padding: 1px;
			transition: 300ms;
			box-sizing: border-box;
			&:before {
				display: none;
			}
			&:after {
				content: '\f105';
				font-family: 'fontAwesome';
				font-size: 50px;
				color: #414141;
				line-height: 28px;
			}
			&.slick-prev {
				left: 0;
				transform: rotate(-180deg);
				&:after {
					margin-bottom: 2px;
				}
			}
			&.slick-next {
				right: 0;
				transform: rotate(0);
			}
			&:hover {
				opacity: 0.7;
			}
		}
	}
	@media screen and (max-width: 767px) {
		.partners__slider {
			.slick-arrow {
				margin-top: -15px;
			}
			.slick-slide {
				margin: 0 10px;
			}
		}
	}
}
/* main partners */

/* main advantages */
.advantages {
	position: relative;
	color: #fff;
	overflow: hidden;
	&:after {
		content: '';
		background-color: #072f57;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0.5;
	}
	> img {
		width: 100%;
		top: 0;
		left: 0;
	}
	.container {
		text-align: center;
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		padding: 0 15px;
		z-index: 1;
		transform: translateY(-60%);
	}
	h6 {
		color: #fff;
		margin: 0 0 20px 0;
	}
	.advantages__col, .advantages__img {
		width: 350px;
		@include inline-block;
	}
	.advantages__col {
		&.left {
			text-align: right;
			.advantages__col__block {
				padding-right: 120px;
				.advantages__col__block__img {
					right: 0;
				}
			}
		}
		&.right {
			text-align: left;
			.advantages__col__block {
				padding-left: 120px;
				.advantages__col__block__img {
					left: 0;
				}
			}
		}
		.advantages__col__block {
			position: relative;
			margin-top: 100px;
			.advantages__col__block__img {
				width: 90px;
				height: 90px;
				border-radius: 100%;
				background-color: #fff;
				position: absolute;
				padding: 22px 15px;
				top: 0;
				box-sizing: border-box;
				img {
					display: block;
					max-width: 100%;
					margin: 0 auto;
				}
			}
		}
	}
	.advantages__img {
		margin-top: 140px;
		img {
			display: block;
			max-width: 100%;
			margin: 0 auto;
		}
		.advantages__img__title {
			display: block;
			font-family: $light;
			font-size: 22px;
			text-transform: uppercase;
			text-align: center;
			color: #fff;
			margin-top: 40px;
		}
	}
	@media screen and (max-width: 1900px) {
		> img {
			height: 620px;
			width: auto;
		}
	}
	@media screen and (max-width: 1440px) {
		.advantages__col {
			width: 300px;
			&.left {
				.advantages__col__block {
					padding-right: 110px;
				}
			}
			&.right {
				.advantages__col__block {
					padding-left: 110px;
				}
			}
		}
	}
	@media screen and (max-width: 1280px) {
		.advantages__img {
			width: 300px;
		}
	}
	@media screen and (max-width: 991px) {
		.container {
			position: relative;
			top: 0;
			transform:translate(0);
		}
		> img {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: auto;
		}
		.advantages__col {
			display: block;
			width: 100%;
			&.left {
				text-align: left;
				.advantages__col__block {
					padding: 0 0 0 100px;
					.advantages__col__block__img {
						right: auto;
						left: 0;
					}
				}
			}
			&.right {
				.advantages__col__block {
					padding: 0 0 0 100px;
					.advantages__col__block__img {
						right: auto;
						left: 0;
					}
				}
			}
			.advantages__col__block {
				margin: 35px 0;
			}
		}
		.advantages__img {
			display: none;
			margin: 15px auto;
		}
	}
	@media screen and (max-width: 767px) {
		.advantages__col {
			.advantages__col__block .advantages__col__block__img {
				width: 70px;
				height: 70px;
				padding: 15px 10px;
			}
			&.right, &.left {
				.advantages__col__block {
					padding-left: 80px;
				}
			}
		}
	}
}
/* main advantages */

/* main news */
.news {
	.news__col {
		display: block;
		background-color: #efeeee;
		color: #454545;
		text-decoration: none;
		.news__col__img {
			overflow: hidden;
			img {
				display: block;
				width: 100%;
				transition: 400ms;
			}
		}
		.news__col__info {
			padding: 30px 25px;
			h6 {
				min-height: 48px;
				font-size: 14px;
				margin: 0 0 20px 0;
				line-height: 1.2;
				transition: 300ms;
			}
			p {
				min-height: 95px;
				font-family: $italic;
				font-weight: 100;
				line-height: 1;
			}
			.date {
				display: block;
				font-size: 14px;
				color: #063763;
				margin-top: 10px;
			}
		}
		&:hover {
			.news__col__info {
				h6 {
					color: #063763;
				}
			}
			.news__col__img {
				img {
					transform: scale(1.1);
				}
			}
		}
	}
	@media screen and (max-width: 991px) {
		.news__col {
			margin-bottom: 25px;
			.news__col__info {
				padding: 20px;
				h6, p {
					min-height: 0px;
				}
				h6 {
					margin-bottom: 15px;
				}
			}
		}
	}
}
/* main news */

/* main content */