/* footer */
footer {
	color: #fff;
	font-size: 16px;
	ul {
		@include ul-default;
		li {
			margin: 0 0 15px 0;
			a {
				&:hover {
					color: #fff;
					text-decoration: underline;
				}
			}
			&.active a {
				text-decoration: underline;
			}
		}
	}
	a {
		color: #fff;
		font-size: 16px;
		&:focus {
			color: #fff;
		}
	}
	h4 {
		color: #fff;
		margin: 0 0 35px 0;
	}
	.footer--content {
		position: relative;
		overflow: hidden;
		padding-top: 40px;
		&:after {
			content: '';
			background-color: rgba(0,0,0,0.7);
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
		}
		> img {
			display: block;
			width: 100%;
			filter: grayscale(100%);
			position: absolute;
			top: 0;
			left: 0;
		}
		.container {
			position: relative;
			z-index: 1;
		}
		.footer--logo {
			display: table;
		}
		.footer--phones {
			a {
				display: block;
			}
			.left {
				@include inline-block;
			}
			.right {
				width: calc(100% - 40px);
				padding-left: 5px;
				box-sizing: border-box;
				@include inline-block;
			}
		}
		.socials {
			display: table;
			margin-top: 40px;
			padding: 40px 20px 20px 0;
			border-top: 1px solid #6b6b6b;
			ul {
				li {
					@include inline-block;
					margin: 0px 35px 10px 0;
					a {
						display: block;
						width: 40px;
						height: 40px;
						text-align: center;
						border: 1px solid #909090;
						font-size: 20px;
						color: #e7e7e7;
						padding-top: 5px;
						box-sizing: border-box;
						&:hover {
							opacity: 0.7;
						}
					}
				}
			}
		}
	}
	.container-fluid {
		min-height: 67px;
		border-top: 1px solid #444444;
		position: relative;
		padding: 25px 0;
		margin-top: 35px;
		z-index: 1;
		p {
			margin: 0;
			@include inline-block;
		}
		.artmedia {
			float: right;
			color: #fff;
			@include inline-block;
			&:hover {
				text-decoration: none;
			}
		}
	}
	@media screen and (max-width: 1380px) {
		.footer--content > img {
			height: 396px;
			width: auto;
		}
	}
	@media screen and (max-width: 1199px) {
		.footer--content > img {
			height: auto;
			width: 100%;
		}
	}
	@media screen and (max-width: 1170px) {
		.footer--content > img {
			height: 100%;
			width: auto;
		}
	}
	@media screen and (max-width: 991px) {
		h4 {
			margin-bottom: 20px;
		}
		ul li {
			margin-bottom: 10px;
		}
		.footer--content {
			padding-top: 20px;
			.socials {
				padding: 25px 0;
				margin-top: 30px;
			}
		}
	}
	@media screen and (max-width: 680px) {
		.container-fluid {
			padding: 15px 0;
			margin-top: 20px;
			p {
				display: block;
				text-align: center;
			}
			.artmedia {
				display: table;
				float: none;
				margin: 10px auto 0;
			}
		}
	}
}
/* footer */