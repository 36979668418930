/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal;
}
/* Connection Font Icons */

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 13, 2018 */
@font-face {
    font-family: 'DINPro-Light';
    src: url('../fonts/DINPro-Light.eot');
    src: url('../fonts/DINPro-Light.eot?#iefix') format('embedded-opentype'),
         url('../fonts/DINPro-Light.woff') format('woff'),
         url('../fonts/DINPro-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'DINPro-LightItalic';
    src: url('../fonts/DINPro-LightItalic.eot');
    src: url('../fonts/DINPro-LightItalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/DINPro-LightItalic.woff') format('woff'),
         url('../fonts/DINPro-LightItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'DINPro-Italic';
    src: url('../fonts/DINPro-Italic.eot');
    src: url('../fonts/DINPro-Italic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/DINPro-Italic.woff') format('woff'),
         url('../fonts/DINPro-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'DINPro-Bold';
    src: url('../fonts/DINPro-Bold.eot');
    src: url('../fonts/DINPro-Bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/DINPro-Bold.woff') format('woff'),
         url('../fonts/DINPro-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'DINPro-Medium';
    src: url('../fonts/DINPro-Medium.eot');
    src: url('../fonts/DINPro-Medium.eot?#iefix') format('embedded-opentype'),
         url('../fonts/DINPro-Medium.woff') format('woff'),
         url('../fonts/DINPro-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'DINPro-MediumItalic';
    src: url('../fonts/DINPro-MediumItalic.eot');
    src: url('../fonts/DINPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/DINPro-MediumItalic.woff') format('woff'),
         url('../fonts/DINPro-MediumItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DINPro';
    src: url('../fonts/DINPro.eot');
    src: url('../fonts/DINPro.eot') format('embedded-opentype'),
         url('../fonts/DINPro.woff2') format('woff2'),
         url('../fonts/DINPro.woff') format('woff'),
         url('../fonts/DINPro.ttf') format('truetype'),
         url('../fonts/DINPro.svg#DINPro') format('svg');
}

@font-face {
    font-family: 'chupada_thinregular';
    src: url('../fonts/chupada_thin-webfont.eot');
    src: url('../fonts/chupada_thin-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/chupada_thin-webfont.woff2') format('woff2'),
         url('../fonts/chupada_thin-webfont.woff') format('woff'),
         url('../fonts/chupada_thin-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

$regular: 'DINPro';
$light: 'DINPro-Light';
$lightItalic: 'DINPro-LightItalic';
$bold: 'DINPro-Bold';
$medium: 'DINPro-Medium';
$mediumItalic: 'DINPro-MediumItalic';
$italic: 'DINPro-Italic';
$chupada: 'chupada_thinregular';

@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}

*:after, *:before {
	display: block;
	line-height: 1;
}

body {
	display: flex;
	height: 100vh;
	position: relative;
	flex-direction: column;
	font-family: $regular;
	color: #414141;
	padding-left: 260px;
	margin: 0;
	@media screen and (max-width: 1440px) {
		padding-left: 220px;
	}
	@media screen and (max-width: 1199px) {
		padding-left: 0;
		padding-top: 93px;
		overflow-x: hidden;
	}
}


h1 {
	position: relative;
	font-family: $medium;
	font-size: 34px;
	text-transform: uppercase;
	text-align: center;
	color: #414141;
	line-height: 1;
	padding-bottom: 28px;
	margin-bottom: 50px;
	&:after {
		content: '';
		height: 2px;
		width: 70px;
		background-color: #063763;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin: 0 auto;
	}	
	a {
		&:hover {
			text-decoration: none!important;
		}
	}
	@media screen and (max-width: 991px) {
		font-size: 32px;
		padding-bottom: 20px;
		margin-bottom: 25px;
	}
	@media screen and (max-width: 768px) {
		font-size: 28px;
	}
}
h2 {
	position: relative;
	font-family: $medium;
	font-size: 34px;
	text-align: center;
	color: #414141;
	padding-bottom: 28px;
	line-height: 1;
	margin: 0 0px 50px;
	&:after {
		content: '';
		height: 2px;
		width: 70px;
		background-color: #063763;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin: 0 auto;
	}	
	a {
		font-size: 34px!important;
		text-transform: uppercase;
		color: #414141;
		&:hover {
			text-decoration: none!important;
		}
	}
	@media screen and (max-width: 991px) {
		font-size: 30px;
		padding-bottom: 20px;
		margin-bottom: 25px;
		a {
			font-size: 30px!important;
		}
	}
	@media screen and (max-width: 768px) {
		font-size: 24px;
		a {
			font-size: 24px!important;
		}
	}
}
h3 {
	font-family: $medium;
	font-size: 24px;
	text-transform: uppercase;
	color: #414141;
	@media screen and (max-width: 991px) {
		font-size: 20px;
	}
}
h4 {
	font-family: $medium;
	font-size: 20px;
	color: #414141;
}
h5 {
	font-family: $medium;
	font-size: 18px;
	color: #414141;
	text-transform: uppercase;
}
h6 {
	font-family: $medium;
	font-size: 16px;
	text-transform: uppercase;
	color: #414141;
}
a {
	color: #414141;
	transition: 350ms;
	text-decoration: none;
	&:focus {
		color: #414141;
		text-decoration: none;
	}
	&:hover {
		color: #063763;
		text-decoration: underline;
	}
}
input, textarea, button, select {
	border-radius: 0px;
	-webkit-appearance: none;
}
strong {
	font-family: $bold;
}
.main, .page {
	font-size: 16px;
	flex: 1 0 auto;
	overflow: hidden;
	textarea,
	input[type="text"],
	input[type="email"] {
		background-color: #fff;
		font-family: $regular;
		font-size: 16px;
		color: #414141;
		padding: 5px 15px;
		&::placeholder {
			color: #414141;
		}
	}
}
.main {
	.container {
		padding-top: 85px;
		padding-bottom: 85px;
		@media screen and (max-width: 1199px) {
			padding-top: 50px;
			padding-bottom: 50px;
		}
		@media screen and (max-width: 991px) {
			padding-top: 25px;
			padding-bottom: 25px;
		}
	}
}
.container {
	@media screen and (max-width: 1440px) {
		width: 100%;
	}
}
.btn {
	display: block;
	width: 250px;
	height: 45px;
	background-color: transparent!important;
	font-family: $mediumItalic!important;
	font-size: 14px;
	color: #414141;
	cursor: pointer;
	border-radius: 0px;
	border: 1px solid #000;
	padding: 11px 60px 10px 20px!important;
	text-align: center;
	text-transform: uppercase;
	position: relative;
	transition: 400ms;
	overflow: hidden;
	&:before {
		content: '';
		width: 100%;
		position: absolute;
		top: 0;
		left: -100%;
		bottom: 0;
		background-color: #063763;
		transition: 400ms;
		z-index: -1;	
	}
	&:after {
		content: '\f105';
		width: 40px;
		height: 45px;
		font-family: 'fontAwesome';
		font-size: 20px;
		color: #414141;
		position: absolute;
		top: 0px;
		right: 0;
		border-left: 1px solid #414141;
		padding-top: 10px;
		box-sizing: border-box;
		transition: 400ms;
	}
	&:focus, &:active {
		background-color: transparent!important;
		color: #fff;
	}
	&:hover {
		color: #fff!important;
		border: 1px solid #063763;
		&:before {
			left: 0;
		}
		&:after {
			border-left: 1px solid #fff;
			color: #fff;
		}
	}	
	@media screen and (max-width: 768px) {
		font-size: 16px;
	}
}

.check--list {
	@include ul-default;
	li {
		display: block;
		position: relative;
		font-size: 16px;
		padding-left: 25px;
		margin-bottom: 5px;
		&:before {
			content: '\f00c';
			position: absolute;
			font-family: 'fontAwesome';
			font-size: 18px;
			color: #000;
			left: 0;
			top: 0px;
		}
	}
}
.square--list {
	@extend .check--list;
	font-size: 16px;
	li {
		padding-left: 17px;
		&:before {
			font-size: 0;
			width: 5px;
			height: 5px;
			background-color: #072f57;
			top: 8px;
		}
	}
}

.box {
	background-color: #f8f8f8;
	padding: 25px 85px;
	border: 2px solid #072f57;
	margin: 25px 0;
	p {
		span {
			font-family: $italic;
		}
	}
}

/* header */
header {
	display: block;
	width: 260px;
	background-color: #072f57;
	font-size: 14px;
	text-transform: uppercase;
	color: #fff;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	padding: 70px 0 0 0;
	z-index: 9;
	ul {
		@include ul-default;
	}
	a {
		color: #fff;
		&:hover {
			text-decoration: none;
		}
	}

	/* header logo */
	.logo {
		display: block;
		text-align: center;
		margin-bottom: 25px;
		outline: none;
	}	
	/* header logo */

	/* header nav */
	.nav {
		ul {
			li {
				border-bottom: 1px solid #1a3f64;
				 a {
					display: block;
					min-height: 50px;
					font-weight: bold;
					padding: 18px 35px 17px 50px;
					position: relative;
					line-height: 1;
					&:after {
						content: '\f105';
						display: none;
						font-family: 'fontAwesome';
						font-size: 16px;
						color: #324a69;
						position: absolute;
						right: 25px;
						top: 50%;
						margin-top: -9px;
						transition: 300ms;
						line-height: 1;
					}
					&:focus {
						color: #fff;
					}
					&:hover {
						color: #f4f5f6;
					}
				}
				&.submenu > a:after {
					display: block;
				}
			} 
		}
		> ul > li {
			position: relative;
			> a {
				&:hover {
					background-color: #1a3f64;
					&:after {
						color: #fff;
					}
				}
			}
			&.active > a {
				color: #f4f5f6;
				background-color: #1a3f64;
				&:after {
					color: #fff;
				}
			}
			ul {
				display: block;
				width: 0px;
				background-color: #093964;
				position: absolute;
				top: 0;
				left: 260px;
				overflow: hidden;
				opacity: 0;
				transition: 400ms;
				&:after {
					content: '';
					background-color: #093964;
					position: fixed;
					width: 0;
					top: 0;
					bottom: 0;
					left: 260px;
					z-index: -1;
					transition: 400ms;
				}
				li {
					a {
						&:hover {
							background-color: #ced7e0;
							color: #1d1f20;
							&:after {
								color: #363636;
							}
						}
					}
					&.active a {
						background-color: #ced7e0;
						color: #1d1f20;
						&:after {
							color: #363636;
						}
					}
				}
				&.show {
					width: 260px;
					opacity: 1;
					&:after {
						width: 260px;
					}
				}
			}
		}
	}
	/* header nav */

	/* header langs */
	.langs {
		display: block;
		text-align: center;
		margin: 15px 0;
		ul li {
			margin: 5px 8px;
			@include inline-block;
			a {
				font-family: $medium;
				font-size: 14px;
				text-transform: uppercase;
				color: #778496;
				&:hover {
					color: #fff;
				}
			}
			&.active a {
				color: #fff;
			}
		}
	}
	/* header langs */

	.year {
		display: block;
		font-family: $chupada;
		font-size: 45px;
		text-transform: uppercase;
		text-align: center;
		color: #fff;
		margin-top: 45px;
	}
	@media screen and (max-width: 1440px) {
		width: 220px;
		.nav {
			ul li a {
				padding: 18px 35px 17px 25px;
				&:after {
					right: 20px;
				}
			}
			> ul > li ul {
				left: 220px;
				&:after {
					left: 220px;
				}
			}
		}
	}
	@media screen and (max-width: 1199px) {
		width: 100%;
		padding: 10px 0;
		bottom: auto;
		.logo {
			display: block;
			width: 100px;
			margin: 0 auto;
			img {
				display: block;
				width: 100%;
			}
		}
		.langs {
			position: absolute;
			top: -10px;
			right: 10px;
		}
		.year {
			font-size: 38px;
			position: absolute;
			left: 80px;
			top: 20px;
			margin: 0;
		}
		.nav {
			width: 100%;
			background-color: #072f57;
			position: fixed;
			top: 0;
			bottom: 0;
			left: -100%;
			padding-top: 40px;
			z-index: 1;
			overflow: auto;
			transition: 400ms;
			.close {
				display: block;
				width: 40px;
				height: 40px;
				position: absolute;
				top: 0;
				right: 0;
				opacity: 0;
				z-index: 1;
				span {
					height: 3px;
					width: 80%;
					background-color: #fff;
					position: absolute;
					left: 0;
					right: 0;
					top: 50%;
					margin: -1px auto;
					transform: rotate(45deg);
					&:last-child {
						transform: rotate(-45deg);
					}
				}
			}
			ul li a {
				min-height: 0px;
			}
			> ul > li > ul {
				display: none;
				width: 100%;
				position: relative;
				left: 0;
				padding: 0;
				opacity: 1;
				transition: 0ms;
				&:after {
					display: none;
				}
				li a {
					font-size: 12px;
					padding: 10px 20px 10px 35px;
				}
			}
			&.open {
				left: 0;
			}
		}
		.nav--btn {
			width: 40px;
			height: 30px;
			border: 1px solid #fff;
			position: absolute;
			left: 15px;
			top: 31px;
			padding: 0;
			margin: 0;
			outline: none;
			float: none;
			span {
				position: absolute;
				left: 0;
				right: 0;
				width: 80%;
				height: 3px;
				margin: 0 auto;
				background-color: #fff;
				transition: 350ms;
				&:nth-of-type(1) {
					top: 6px;
				}
				&:nth-of-type(2) {
					top: 13px;
				}
				&:nth-of-type(3) {
					top: 20px;
				}
			}
			&.open {
				span {
					&:nth-of-type(1) {
						top: 13px;
						transform: rotate(45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						top: 13px;
						transform: rotate(-45deg);
					}
				}
			}
		}
	}
	@media screen and (max-width: 580px) {
		.langs ul li {
			margin: 5px 4px;
		}
		.year {
			font-size: 22px;
			left: 64px;
  			top: 32px;
		}
	}
}
/* header */